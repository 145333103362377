import request from '@/api'

/**
 * 获取我的企业列表
 */
export function getMyCom() {
    return request({
        url: 'com/getMyCom',
        method: 'post',
        data: {}
    })
}

// 获取企业认证信息
export function getComInfo() {
    return request({
        url: 'cominfo/getCert',
        method: 'post',
        data: {}
    })
}

// 企业用户列表
export function getComUser(data) {
    return request({
        url: 'com/getComUserGrid',
        method: 'post',
        data: data
    })
}

// 获取企业角色列表
export function getComRole() {
    return request({
        url: 'com/role-list',
        method: 'post',
        params: {}
    })
}


// 企业分配角色
export function setComRole(data) {
    return request({
        url: 'com/changeRole',
        method: 'post',
        params: {
            roleId: data.roleId,
            userId: data.userId
        }
    })
}

// 移除企业用户
export function removeRole(data) {
    return request({
        url: 'com/removeUser',
        method: 'post',
        params: {
            userId: data.userId
        }
    })
}

// 提交企业认证
export function SetComInfo(data) {
    return request({
        url: 'cominfo/addCert',
        method: 'post',
        data: {
            comCode: data.comCode,
            contactName: data.contactName,
            contactAddress: data.contactAddress,
            contactEmail: data.contactEmail,
            contactPhone: data.contactPhone,
            comFinanceAddress: data.comFinanceAddress,
            comFinanceName: data.comFinanceName,
            comFinancePhone: data.comFinancePhone,
            comFinanceTax: data.comFinanceTax,
            comId: data.comId,
            licenceImg: data.licenceImg,
            comName: data.comName,
            comNote: data.comNote,
            licenceCode: data.licenceCode,
            licenceType: data.licenceType,
            legalName: data.legalName,
            legalPhone: data.legalPhone,
            certId: data.certId,
            certStatus: data.certStatus,
        }
    })
}

// 企业上传base64图片
export function uploadComImg(data) {
    return request({
        url: 'oss/file/baseImg',
        method: 'post',
        data: {
            imgStr: data.imgStr,
        },
    })
}

// 生成邀请链接
export function createInvitationCode(data) {
    return request({
        url: 'invite/init',
        method: 'post',
        params: {
            roleId: data.roleId
        }
    })
}
