import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './auth'
import cookies from 'vue-cookies'
import './icons'
import store from './store'
import Tinymce from "@/components/Tinymce"
import './styles/index.scss'
import ElementUI from 'element-ui'
// import './styles/element-ui.css'  //紫光紫色
import './styles/elementUI-4282C4.css' //深蓝色
import preventReClick from '@/api/preventReClick'; //防多次点击，重复提交
import BaiduMap from 'vue-baidu-map'
import animated from 'animate.css'

Vue.prototype.$cookies = cookies;

Vue.config.productionTip = false

// 全局修改默认配置，点击空白处不能关闭弹窗
ElementUI.Dialog.props.closeOnClickModal.default = false
// 全局修改默认配置，按下ESC不能关闭弹窗
ElementUI.Dialog.props.closeOnPressEscape.default = false
// 全局修改默认配置，清除由dialog滑动条产生的窗口左右抖动偏移
ElementUI.Dialog.props.lockScroll.default = false;

Vue.use(ElementUI, {
    size: 'small',
})

Vue.use(preventReClick)

Vue.component('Tinymce', Tinymce)
Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: 'q1CCVnXz2zN2XUwHkkIK0FDjNALiNTs8'
})

Vue.use(animated)

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')

// import formCreate from '@form-create/element-ui';
// Vue.use(formCreate);
// formCreate.register({
//     name: 'option',
//     init({val}, rule) {
//         setTimeout(() => {
//             rule.options.push({label: val, value: val}, {label: val + val, value: val + val})
//         }, 300)
//     }
// })

