<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/" @click.native='logoclick()'>
        <img v-if="logoIcon" :src="logoIcon" style="height: 40px;margin-top: 10px">
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/" @click.native='logoclick()'>
        <img v-if="logo" :src="logo" style="height: 50px;margin-top: 10px">
      </router-link>
    </transition>
  </div>
</template>

<script>
import mylogo from '@/assets/aiqcloud/home/logo.png';
import logoIcon from '@/assets/logoIcon.png';

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: '蓝海AI智能服务云平台',
      logo: mylogo,
      logoIcon: logoIcon
    }
  },
  methods: {
    logoclick() {
      this.$store.commit('user/SET_WORKSHOPNAME', '');
      this.$store.dispatch('app/setWorkshopName')
    },
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  /*background: #2b2f3a;*/
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      vertical-align: middle;
      object-fit: none;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #4282c4;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
