import router from './router'
import {getToken} from "@/utils/token";
import {isNull} from "@/utils";
import store from './store'

router.beforeEach(async (to, from, next) => {
    const whiteList = [
        '/solution/photovoltaicIndustry', '/solution/packagingIndustry', '/solution/NewEnergyIndustry', '/solution/medicineIndustry', '/solution/electronicsIndustry',
        '/login', '/show', '/auth-redirect',
        '/home', '/none','/license/expire',
        '/ai', '/market', '/market/project', '/about/aivae', '/about/cvae',
        '/solution', '/solution/expand', '/solution/mark', '/solution/model', '/solution/visual', '/solution/edge', '/solution/aoi',
        '/case', '/case/pack', '/case/energy', '/case/medicine', '/case/photovoltaic', '/case/electronics', '/case/none',
        '/ecology', '/cooperation/form', '/cooperation/register',
        '/about', '/cooperation', '/solution', "/solution/chipIndustry", '/solution/newEnergyIndustry', '/about/mae', '/about/aivae', '/about/cvae', '/about/apply'
    ]

    document.documentElement.scrollTop = 0;
    if (!isNull(to.meta.title)) {
        document.title = to.meta.title
    } else {
        document.title = '蓝海AI智能服务云平台'
    }
    let hasToken = getToken()
    if (hasToken) {
        const checkRoutes = store.getters.check_routes
        if (checkRoutes) {
            next()
        } else {
            let accessRoutes = await store.dispatch('permission/generateAllRoutes');
            router.addRoutes(accessRoutes)
            next({...to, replace: true})
        }
    } else {
        // 白名单不用权限验证
        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            next()
        } else {
            next(`/login`)
            // var reg = new RegExp(/console/);
            // if (to.path.match(reg)) {
            //     next(`/login?redirect=${to.path}`)
            // } else {
            //     next(`/login?redirect=${to.path}`)
            // }
        }
    }
})
