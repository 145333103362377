<template>
  <div class="has-logo" style="text-align: left">
    <logo :collapse="isCollapse" style="margin-bottom: 10px"/>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
          :default-active="activeIndex"
          :collapse="isCollapse"
          :background-color="variables.menuBg"
          :text-color="variables.menuText"
          :unique-opened="false"
          :active-text-color="variables.menuActiveText"
          :default-openeds="openlist"
          @select="handleSelect"
          @open="handleOpen"
          :collapse-transition="false">
        <sidebar-item
            v-for="route in getRoutes()"
            :key="route.path"
            :item="route"
            :base-path="route.path"/>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";

export default {
  components: {SidebarItem, Logo},
  data() {
    return {
      activeIndex: '',
      openlist: ["/console/project", "/console/com"],
    };
  },
  methods: {
    handleOpen(index) {
    },
    handleSelect(keyPath) {
      sessionStorage.setItem('consolePath', keyPath);
    },
    getRoutes() {
      let reg = new RegExp(/console/);
      let ret = [];
      for (let i = 0; i < this.permission_routes.length; i++) {
        if (this.permission_routes[i].path.match(reg)) {
          ret.push(this.permission_routes[i]);
        }
      }
      return ret;
    },
  },
  mounted() {
    this.activeIndex = sessionStorage.getItem('consolePath') || '/console/workbench'
  },
  computed: {
    ...mapGetters(["permission_routes", "sidebar"]),
    variables() {
      return {
        menuText: "#333333",
        menuActiveText: "#4282c4",
        subMenuActiveText: "#4282c4",
        menuBg: "#f1f1f1",
        menuHover: "#fff",
        subMenuBg: "#f1f1f1",
        subMenuHover: "#fff",
      };
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
};
</script>
